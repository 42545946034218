import React from 'react'
import ReactDOM from 'react-dom'

import useUser from 'Hooks/API/useUser.hook'

import { RootS } from './Support.styled'

const Support = () => {
  const { data: user } = useUser()
  const link = user?.isOnTrialPeriod ? (
    'https://wa.me/5521974299868?text=Olá,+estou+usando+o+NeoDash+e+tenho+uma+duvida.+Poderia+me+ajudar?'
  ) : (
    'https://wiki.neodash.ai/submit-a-ticket/'
  )

  return (
    ReactDOM.createPortal(
      <RootS id="botao_suporte" className='support' target="_blank" href={link}>
        <img src="/images/icon-headset.svg" alt="Suporte" />
      </RootS>
      , document.getElementById('root') || document.createElement('div'))
  )
}

export default Support
