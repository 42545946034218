import { useIntl } from 'react-intl'

import { Col, Row } from 'DesignSystem'
import styled, { css } from 'styled-components'

const HelpLinksRoot = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 0;

  a {
      ${props => css(props.theme.fonts.types.body2)};
  }

  @media (max-width: 768px) {
    width: 100%;
    left: 0;

    a {
      ${props => css(props.theme.fonts.types.caption1)};
    }
  }

  @media (max-height: 700px) {
    left: 0;
  }
`

function HelpLinks () {
  const intl = useIntl()

  return (
    <HelpLinksRoot>
      <Row className="text-center py-3">
        <Col xs={2}>
          <a className="text-decoration-none text-black" href="http://neodash.ai/about-us/" target='_blank' rel="noreferrer">
            {intl.formatMessage({ id: 'Sobre' })}
          </a>
        </Col>
        <Col className='p-0'>
          <a className="text-decoration-none text-black" href="https://wiki.neodash.ai/" target="_blank" rel="noreferrer">
          {intl.formatMessage({ id: 'Central de Ajuda' })}
          </a>
        </Col>
        <Col className='p-0'>
          <a className="text-decoration-none text-black" href="http://neodash.ai/termo-de-uso">
            {intl.formatMessage({ id: 'Termos de Uso' })}
          </a>
        </Col>
        <Col className='p-0'>
          <a className="text-decoration-none text-black" href="http://neodash.ai/privacidade-seguranca" target='_blank' rel="noreferrer">
            {intl.formatMessage({ id: 'Política de Privacidade' })}
          </a>
        </Col>
      </Row>
    </HelpLinksRoot>
  )
}

export default HelpLinks
